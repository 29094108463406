body {
  color: #ffffff;
}

.container_movie {
  display: flex;
  padding: 100px;
  height: 60vh;
  background: linear-gradient(30deg, rgba(0, 0, 0, 0.404), #1f1d1d, rgba(0, 0, 0, 0.404));
  filter: blur(200%);
  transition: .5s ease-in-out;

}
.container_movie h1 {
  text-transform: uppercase;
  margin-top: -5%;
  font-weight: normal;
  border-bottom: 1px solid #ffffff;
}

.sub_movie {
  margin-left: 2%;
}

.sub_movie p {
  background-color: #8a8a8a36;
  border-radius: 10px;
  padding: 5px;
  display: inline-block;
  color: #ffffff;
  font-weight: bold;
}

.sinop {
  margin-left: 2%;
  text-align: start;
  color: #7c7c7c;
}

.container_movie h2 {
  width: 70%;
  font-family: Arial, Helvetica, sans-serif;
  border: 1px solid rgba(255, 255, 255, 0.616);
  padding: 15px;
  font-size: 1.0em;
  border-radius: 20px;
  margin-left: 2%;
}

.container_movie img {
  width: 250px;
  height: 350px;
  padding-top: 30%;
}

.container_movie button {
  padding: 10px;
  font-size: 20px;
  transition: .5s ease-out;
  border: 1px solid transparent;
  margin: 3% 0 3% 2%;
  font-weight: bold;
  font-family: fantasy sans-serif;
}

.container_movie button:hover {
  background-color: #000000;
  color: #ffffff;
  border: 1px solid rgba(255, 255, 255, 0.616);
  transition: .5s ease-in-out;
  cursor: pointer;
}

.sinopseOn {
  display: inline-block;
}

.sinopseOF {
  display: none;
}

.container_refresh img {
  width: 20px;
  height: 20px;
  filter: invert(200%);
  cursor: pointer;
}