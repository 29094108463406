html,
body {
  background-color: #000000;
  margin: 0;
  padding: 0;
  width: 100%;
  overflow-x: hidden;
}


.player {
  color: gray;
}


.Ultimos h2{
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;  
  font-size: 1.2em;
}

.display_home {
  display: grid;
}

.loading{
  display: flex;
  justify-content: center;
  margin-top: 10%;
}

.loading img{
  filter:contrast(200%);
  width: 150px;
}