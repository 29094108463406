/*SubMenuMobile*/
.subMenu_mobile {
    border-top: 1px solid #ffffff;
    border-bottom: 1px solid #ffffff;
    justify-content: flex-start;
    display: flex;
    text-align: center;
}

.subMenu_mobile a {
    color: #ffffff;
    text-decoration: none;
    padding: 5px;
    border-bottom: 1px solid #ffffff;
}

.Ultimos h1{
    font-family: Arial, Helvetica, sans-serif;
    text-transform: uppercase;
    margin-left: 13%; 
}

.container_filmes {
    color: rgb(255, 255, 255);
}

.container_filmes input {
    padding: 5px;
    border-radius: 10px;
}

.container_filmes h2 {
    font-family: 'fantasy', sans-serif;
    text-align: start;
}

.container_filmes li {
    border-bottom: 1px solid rgb(17, 17, 17);
    margin: 2px;
    padding: 1px;
    list-style-type: circle;
    transition: .5s ease-out;
}

.container_filmes li:hover {
    cursor: pointer;
    transition: .5s ease-out;
    color: #da1616;
}


.resultadoBarra {
    max-height: 10em;
    overflow-x: hidden;
}

.container_categoria {
    width: 15%;
    height: 100%;
    padding: 10px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.158);
    border-right: 1px solid rgba(255, 255, 255, 0.158);
    margin-right: 10%;
    background-color: #0000007e;
    margin-top: 1%;
    border-top-right-radius: 10px;
    position: fixed;
}

.container_categoria p a {
    text-decoration: none;
    font-size: 2.0em;
    color: rgba(224, 0, 0, 0.76);
    font-weight: bolder;
    font-family: Arial, Helvetica, sans-serif;
}

.container_categoria ul a {
    color: #ffffff;
    text-decoration: none;
    font-size: 1.0em;
}


/*Exibir +-*/
.exibir {
    cursor: pointer;
    font-family: Arial, Helvetica, sans-serif;
    margin-left: 5%;
}

.exibir:hover {
    color: #da1616;
}