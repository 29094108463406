.container_detalhesPai a {
  display: flex;
}


.container_subTemporada a {
  text-align: center;
  justify-content: center;
  display: flex;
}

.container_movie {
  height: 100%;
}


/*Drop Down*/

.container_menu {
  display: flex;
  justify-content: center;
}



.sub_menuDrop li {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  margin-bottom: 2px;
}

.menu {
  list-style: none;
  border: 1px solid #c0c0c0;
  float: left;
  z-index: 1;
}

.menu li {
  position: relative;
  float: left;
  border-right: 1px solid #c0c0c0;
}

.menu li {
  color: rgb(255, 255, 255);
  text-decoration: none;
  padding: 5px 10px;
  display: block;
}

.menu li:hover {

  color: rgb(255, 4, 4);
  cursor: pointer;
}

.menu li ul {
  position: absolute;
  top: 25px;
  left: 0;
  background-color: rgb(0, 0, 0);
  display: none;
}

.menu li ul li {
  border: 1px solid #c0c0c0;
  display: block;
  width: 150px;
  margin-top: 2px;
}

.menu li:hover ul,
.menu li.over ul {
  display: block;

}