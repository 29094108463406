/*carrosel pag filmes*/
.all_carouselMovie {
  width: 800%;
  max-width: 1250px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.363);
  margin-bottom: 5%;
}


/*-----------------------------*/


.all_carouselMovie p {
  font-size: 1.2em;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}


.all_carousel {
  width: 100%;
  max-width: 1250px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;

}

.inner {
  display: flex;
}

.item {
  min-height: 200px;
  min-width: 200px;
  padding: 14px;
}

.item img {
  width: 100%;
  height: 90%;
  border-radius: 12px;
  box-shadow: 1px 1px 1px 1px rgb(0, 0, 0);
}

.item img:hover {
  cursor: pointer;
}


.carousel {
  cursor: grab;
  overflow: hidden;
}

.carousel_title {
  font-size: 0.7em;
  font-family: Arial, Helvetica, sans-serif;
  padding-left: 2%;
}

.GifCarregamento {
  max-width: 350px;
  max-height: 350px;
  filter: contrast(200%);
}