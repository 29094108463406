body {
    background-color: #000000;
    font-family: 'Roboto', sans-serif;
    font-weight: bolder;
}

.container_detalhesPai {
    text-align: center;
    display: flex
}

.container_detalhes {
    padding: 2px;
    border: 1px solid #ffffff21;
    background-color: rgb(0, 0, 0);
    margin: 0 5% 10% 0;
}

.container_detalhes a {
    margin-right: 10%;
}

/* .container_detalhes img{
    border-radius: 10px;
    transition: .2s ease-in;
    min-width: 150px;
    max-height: 250px;
} */

.container_detalhes img:hover {
    opacity: 0.2;
}

.container_detalhes a:hover {
    cursor: pointer;
    background-image: url('../assents/setWhit.png');
    background-repeat: no-repeat;
    background-size: 50%;
    background-position: center;
}

.grid-container span {
    justify-content: center;
    display: flex;
    text-align: center;
    margin-left: 10%;
}

.grid-item {
    margin: 5px;
    text-align: center;
    font-size: 1.5em;
}

.container_detalhes p {
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}